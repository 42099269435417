import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './Product2.css';
import API_URL from '../../config';
import Login from '../Login/Login';
import Navbar from '../Navbar/Navbar';

const Products = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [productList, setProductList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [notification, setNotification] = useState('');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // Login modal state

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    filterProducts();
  }, [productList, searchTerm]);

  const getAllProducts = async () => {
    try {
      const res = await fetch(`${API_URL}/products`, { method: "GET", credentials: 'include' });
      if (!res.ok) {
        throw new Error('Tırt');
      }
      const result = await res.json();
      const shuffledProducts = result.sort(() => Math.random() - 0.5);
      setIsLoaded(true);
      setProductList(shuffledProducts);
    } catch (error) {
      console.error("Fetch error:", error);
      setIsLoaded(true);
      setError(error);
    }
  };

  const filterProducts = () => {
    if (!searchTerm) {
      setFilteredProductList(productList);
    } else {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      const filteredList = productList.filter(product =>
        product.name.toLowerCase().includes(lowercasedSearchTerm) ||
        product.description.toLowerCase().includes(lowercasedSearchTerm)
      );
      setFilteredProductList(filteredList);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const addToBasket = async (productId) => {
    const basketId = localStorage.getItem('basketId');
    const token = localStorage.getItem("token");

    if (!token) {
      setIsLoginModalOpen(true);
      return;
    }
    try {
      const response = await fetch(`${API_URL}/api/basketitems/addItem/${productId}/${basketId}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("token")
        }
      });
      if (!response.ok) {
        throw new Error("Ürün sepete eklenirken hata oluştu");
      }
      const data = await response.json();
      console.log("Sepete eklenen ürün:", data);
      setNotification('Ürün sepete eklendi!');
      setTimeout(() => setNotification(''), 3000);
    } catch (error) {
      console.error("Sepete ekleme hatası:", error);
      setNotification('');
    }
  };

  const closeModal = () => {
    setIsLoginModalOpen(false);
  
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="product-grid" id="special-page">
        <div className="header-container">
          <input
            type="text"
            placeholder="Arama yap..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search__input"
          />
        </div>

        {/* Bildirim gösterme */}
        {notification && (
          <div className="notification">{notification}</div>
        )}

        {/* Login Modal */}
        {isLoginModalOpen && (
          <div className="modal-overlay"  onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h1>Giriş</h1>
              <Login closeModal={closeModal} onLoginSuccess={() => {
                closeModal();
                setNotification('Giriş başarılı! Sepete eklemeye devam edebilirsiniz.');
                setTimeout(() => setNotification(''), 3000);
                window.location.reload();
              }} />
            </div>
          </div>
        )}

        {filteredProductList.map((product) => (
          <div className="product-card" key={product.id}>
            <Link style={{ color: "white" }} to={`/product/${product.id}`}>
              <img src={product.imageUrl} alt={product.name} />
              <h3>{product.name}</h3>
            </Link>
            <p>{product.description}</p>
            <p className="price">{product.price} ₺</p>
            <p>Stok : {product.stock}</p>
            {product.stock <= 0 ? (
              <p style={{ color: "red", fontStyle: "oblique" }}>Tükendi</p>
            ) : product.stock <= 10 ? (
              <p style={{ color: "red", fontStyle: "oblique" }}>Tükenmek Üzere!</p>
            ) : null}

            <p>
              <button
                onClick={() => addToBasket(product.id)}
                disabled={product.stock <= 0} // Eğer stok 0 veya daha azsa butonu pasif yap
                style={{
                  backgroundColor: product.stock <= 0 ? "gray" : "#0c7d6d", // Pasifken gri yap
                  cursor: product.stock <= 0 ? "not-allowed" : "pointer" // Pasifken "not-allowed" yap
                }}
              >
                Sepete Ekle
              </button>
            </p>
          </div>
        ))}
      </div>
    );
  }
};

export default Products;
