import React, { useState, useEffect } from "react";
import { Trash, PlusCircle, XCircle, Pencil } from "@phosphor-icons/react";
import "./Product.css";
import API_URL from "../../config";

function Products() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [productList, setProductList] = useState([]);
    const [filteredProductList, setFilteredProductList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [modalMode, setModalMode] = useState(null);
    const [productForm, setProductForm] = useState({ name: '', description: '', price: '', categoryId: '', stock: '', imageUrl: '' });
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [categories, setCategories] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });


    useEffect(() => {
        setUserRole(localStorage.getItem('role'));

        if (userRole === 'ADMIN') {
            getAllProducts();
            fetchCategories();
        }
    }, [userRole]);

    useEffect(() => {
        filterProducts();
    }, [productList, searchTerm]);

    const getAllProducts = async () => {
        try {
            const res = await fetch(`${API_URL}/products`, { method: "GET", credentials: 'include' });
            if (!res.ok) {
                throw new Error('Network response was not ok.');
            }
            const result = await res.json();
            setIsLoaded(true);
            setProductList(result);
        } catch (error) {
            setIsLoaded(true);
            setError(error);
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedList = [...filteredProductList].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setFilteredProductList(sortedList);
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${API_URL}/category`);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    const filterProducts = () => {
        if (!searchTerm) {
            setFilteredProductList(productList);
        } else {
            const lowercasedSearchTerm = searchTerm.toLowerCase();
            const filteredList = productList.filter(product =>
                product.name.toLowerCase().includes(lowercasedSearchTerm) ||
                product.description.toLowerCase().includes(lowercasedSearchTerm) ||
                product.price.toString().includes(lowercasedSearchTerm)
            );
            setFilteredProductList(filteredList);
        }
    };

    const deleteProduct = async (productId) => {
        try {
            const response = await fetch(`${API_URL}/products/${productId}`, { method: "DELETE", credentials: 'include' });
            if (!response.ok) {
                console.error("Silinemedi");
                return;
            }
            setProductList(productList.filter(p => p.id !== productId));
        } catch (error) {
            console.error("Silinemedi");
        }
    };

    const saveProduct = async () => {
        const url = modalMode === "add" ? `${API_URL}/products` : `${API_URL}/products/${selectedProductId}`;
        const method = modalMode === "add" ? "POST" : "PUT";

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("token")
                },
                credentials: 'include',
                body: JSON.stringify(productForm)
            });

            if (!response.ok) {
                console.error(modalMode === "add" ? "Eklenemedi" : "Güncellenemedi");
                return;
            }
            setModalMode(null);
            getAllProducts();
        } catch (error) {
            console.error(modalMode === "add" ? "Eklenemedi" : "Güncellenemedi");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProductForm({ ...productForm, [name]: value });
    };
    const handleCategoryChange = (event) => {
        setProductForm({
            ...productForm,
            categoryId: event.target.value,
        });
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const openAddModal = () => {
        setModalMode("add");
        setProductForm({ name: '', description: '', price: '', categoryId: '', stock: '', imageUrl: '' });
    };

    const openUpdateModal = (product) => {
        setModalMode("update");
        setSelectedProductId(product.id);
        setProductForm({ name: product.name, description: product.description, price: product.price, categoryId: product.category.id, stock: product.stock, imageUrl: product.imageUrl });
    };

    const closeModal = () => {
        setModalMode(null);
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (userRole !== 'ADMIN') {
        return <div>Bu sayfayı görüntüleme izniniz yok.</div>;
    } else {
        return (
            <div className="table-container">
                <div className="header-container">
                    <input
                        type="text"
                        placeholder="Arama yap..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <button onClick={openAddModal} className="add-product-button">
                        <div className="addProductButtonContainer">
                            <PlusCircle size={32} />
                            <span>Yeni Ürün Ekle</span>
                        </div>
                    </button>
                </div>

                <table className="post-table">
                    <thead>
                        <tr>
                            {//{sortConfig.key === 'name' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                            }
                            <th onClick={() => handleSort('name')}>Ürün Adı</th>
                            <th onClick={() => handleSort('description')}>Açıklama</th>
                            <th onClick={() => handleSort('price')}>Fiyat</th>
                            <th onClick={() => handleSort('category')}>Kategori</th>
                            <th onClick={() => handleSort('stock')}>Stok</th>
                            <th onClick={() => handleSort('soldCount')}>Satıldı</th>
                            <th>Resim</th>
                            <th>İşlemler</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProductList.map((product) => (
                            <tr key={product.id}>
                                <td>{product.name}</td>
                                <td width="180px">{product.description}</td>
                                <td>{product.price} ₺</td>
                                <td>{product.category.name}</td>
                                <td>{product.stock}</td>
                                <td>{product.soldCount}</td>
                                <td>
                                    <img src={product.imageUrl} alt={product.name} style={{ width: "100px", height: "auto" }} />
                                </td>
                                <td>
                                    <button className="custom-icon-button" onClick={() => deleteProduct(product.id)}>
                                        <Trash size={25} />
                                    </button>
                                    <button className="custom-icon-button" onClick={() => openUpdateModal(product)}>
                                        <Pencil size={25} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {modalMode && (
                    <div className="modal">
                        <div className="modal-content">
                            <button onClick={closeModal} className="close-modal">
                                <XCircle size={24} />
                            </button>
                            <h2>{modalMode === "add" ? "Yeni Ürün Ekle" : "Ürünü Güncelle"}</h2>
                            <form onSubmit={(e) => { e.preventDefault(); saveProduct(); }}>
                                <label>
                                    Ürün Adı:
                                    <input type="text" name="name" value={productForm.name} onChange={handleInputChange} required />
                                </label>
                                <label>
                                    Açıklama:
                                    <input type="text" name="description" value={productForm.description} onChange={handleInputChange} required />
                                </label>
                                <label>
                                    Fiyat:
                                    <input type="number" name="price" value={productForm.price} onChange={handleInputChange} required />
                                </label>
                                <label>
                                    Kategori:
                                    <select
                                        name="categoryId"
                                        value={productForm.categoryId}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="">Kategori Seçin</option>
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <label>
                                    Stok:
                                    <input type="number" name="stock" value={productForm.stock} onChange={handleInputChange} required />
                                </label>
                                <label>
                                    Resim URL:
                                    <input type="text" name="imageUrl" value={productForm.imageUrl} onChange={handleInputChange} />
                                </label>
                                <button type="submit" className="submit-button">{modalMode === "add" ? "Oluştur" : "Güncelle"}</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Products;
